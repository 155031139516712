import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { Error404Component } from './shared/components/error404/error404.component';

export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'perfil',
        loadChildren: () =>
          import('app/modules/perfil/perfil.module').then(
            (m) => m.PerfilModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'permissao',
        loadChildren: () =>
          import('app/modules/permissao/permissao.module').then(
            (m) => m.PermissaoModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'usuario',
        loadChildren: () =>
          import('app/modules/usuario/usuario.module').then(
            (m) => m.UsuarioModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'perfil-permissao',
        loadChildren: () =>
          import('app/modules/perfil-permissao/perfil-permissao.module').then(
            (m) => m.PerfilPermissaoModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'log',
        loadChildren: () =>
          import('app/modules/log/log.module').then((m) => m.LogModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'cliente',
        loadChildren: () =>
          import('app/modules/ccos/cliente/cliente.module').then(
            (m) => m.ClienteModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'grupo',
        loadChildren: () =>
          import('app/modules/ccos/grupo-servico/grupo-servico.module').then(
            (m) => m.GrupoServicoModule
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'ordem-servico',
        loadChildren: () =>
          import('app/modules/ccos/ordem-servico/ordem-servico.module').then(
            (m) => m.OrdemServicoModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: 'admin',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'enterprise',
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },


  {
    path: '404',
    component: Error404Component,
    canActivate: [AuthGuard],
  },

  { path: '**', pathMatch: 'full', redirectTo: '404' },

];
