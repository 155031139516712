import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { ELocalStorage } from 'app/shared/models/localstorage.model';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  constructor(private _httpClient: HttpClient) {}

  set accessToken(token: string) {
    localStorage.setItem(ELocalStorage.TOKEN, token);
  }

  get accessToken(): string {
    return localStorage.getItem(ELocalStorage.TOKEN) ?? '';
  }

  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post(`${environment.api}/auth/forgot`, email);
  }

  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  signIn(credentials: { login: string; senha: string }): Observable<any> {
    if (this._authenticated) {
      return throwError(() => new Error('Usuário já está autenticado.'));
    }

    return this._httpClient
      .post(`${environment.api}/login`, credentials, {
        observe: 'response',
      })
      .pipe(
        switchMap((response: any) => {
          const [, token] = response.headers.get('Authorization').split(' ');
          this.accessToken = token;
          this._authenticated = true;
          return of(response);
        })
      );
  }

  // Consome o endpoint de refresh
  refreshToken(): Observable<any> {
    return this._httpClient
      .post<any>(`${environment.api}/auth/refresh_token`, null, {
        observe: 'response',
      })
      .pipe(
        map((res) => {
          // Desestrutura o token tirando a string Bearer
          const [, token] = res.headers.get('Authorization').split(' ');

          localStorage.setItem(ELocalStorage.TOKEN, token);

          return token;
        })
      );
  }

  signOut(): Observable<any> {
    localStorage.removeItem(ELocalStorage.TOKEN);
    this._authenticated = false;
    return of(true);
  }

  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return of(true);
  }
}
